$theme-colors: (
    "primary": blue,
    "dark": #000000
);

@import "node_modules/bootstrap/scss/bootstrap";

body {
	background: #1f2944;
    padding-top:56px;
    font-family: "Roboto Slab",Helvetica,Arial,sans-serif;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    @media (min-width: 768px) {
    	font-size: 24px;
    }
}

.pink  {
	color: #eb028b;
}

p.title {
	font-size: 35px !important;
	line-height: 1.2em;
	font-weight: bold;
	@media (min-width: 768px) {
		font-size: 45px;
	}
}

#sec-tshores, #sec-boardwalk {
	padding: 20px 0px;
	max-width: 900px;
	margin: 0 auto;
	img {
		width: 100%;
		height: auto;
	}

	h1 {
		max-width: 80%;
		margin: 0 auto;
		padding-bottom: 35px;
		img {
			max-width: 400px;
		}
	}

	.container-img {
		position: relative;
		.caption {
			position: absolute;
			background: rgba(0,0,0,0.8);
			bottom: 0px;
			left: 0px;
			font-size: 12px;
			padding: 8px 12px;

		}
	}

	h2 {
		max-width: 80%;
		margin: 0 auto;
		padding-bottom: 35px;
		img {
			max-width: 450px;
		}
	}
	.btn {
		background-color: #eb028b;
		color: #ffffff;
		border-radius: 0px;
		transition: all 0.3s;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 16px;
		margin: 10px 0px 20px;
		&:hover {
			background-color: #b6006b;
		}
	}
}

#sec-tshores {
	p {
	font-size: 24px;
		@media (min-width: 768px) {
			font-size: 30px;
		}
	}
	img {
		padding-top: 10px;
	}

}

#sec-boardwalk {
	padding: 50px 0px;
	@media (min-width: 768px) {
		padding-bottom: 100px;
	}
	img {
		padding-top: 20px;
	}
}

.wrapper {
	background: url(../img/bg.gif) repeat-x bottom center;
	background-size: contain;
}

footer {
    border-top: 5px solid #ffffff;
    font-size: 13px;
    padding: 30px 0px 20px;
    text-align: left;
    .container {
    	@media (min-width: 768px) {
	    	display: flex;
	    }
    }
    
    .container-text {
    	@media (min-width: 768px) {
    		width: 60%;
    		display: flex;
    		flex-direction: row;
    	}
    }
    .container-logos {
    	text-align: left;
    	@media (min-width: 768px) {
    		width: 40%;
    		display: flex;
    		flex-direction: row;
    		justify-content: flex-end;
    	}
    	#logo-rennie {
    		padding-left: 20px;
    		@media (min-width: 768px) {
    			padding-left: 30px;
    		}
    	}
    	#logo-aquilini svg {
    		width: 120px;
	    }
	    #logo-rennie svg {
	    	width: 110px;
	    }
    }
    
}